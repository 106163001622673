:root {

  /**
   * colors
   */

  --middle-blue-green: hsl(176, 35%, 63%);
  --sonic-silver: hsl(0, 0%, 44%);
  --eerie-black: hsl(0, 0%, 9%);
  --ocean-green: hsl(148, 45%, 58%);
  --candy-pink: hsl(356, 65%, 63%);
  --cultured: hsl(0, 0%, 96%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);

  /**
   * typography
   */

  --ff-poppins: "Poppins", sans-serif;

  --fs-1: 2.5rem;
  --fs-2: 1.75rem;
  --fs-3: 1.625rem;
  --fs-4: 1.5rem;
  --fs-5: 1.375rem;
  --fs-6: 1.25rem;
  --fs-7: 1.125rem;
  --fs-8: 0.938rem;
  --fs-9: 0.875rem;
  --fs-10: 0.813rem;

  --fw-500: 500;
  --fw-600: 600;

  /**
   * transition
   */

  --transition-1: 0.25s ease;
  --transition-2: 0.75s ease;
  --cubic-out: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-in: cubic-bezier(0.33, 0.85, 0.56, 1.02);

  /**
   * spacing
   */

  --section-padding: 60px;

}





/*-----------------------------------*\
 * #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a,
img,
span,
input,
button,
ion-icon {
  display: block;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

button {
  text-align: left;
  cursor: pointer;
}

input {
  width: 100%;
  outline-color: var(--middle-blue-green);
}

img {
  height: auto;
}

ion-icon {
  pointer-events: none;
}

html {
  font-family: var(--ff-poppins);
  color: var(--sonic-silver);
  scroll-behavior: smooth;
}

body {
  background: var(--white);
}

/* Scrollbar : */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dddddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/*-----------------------------------*\
 * #REUSED STYLE
\*-----------------------------------*/

.container {
  padding-inline: 15px;
}

a,
button {
  transition: var(--transition-1);
}

h3>a {
  color: inherit;
}

.h1,
.h2,
.h3,
.h4 {
  color: var(--eerie-black);
  font-weight: var(--fw-600);
}

.h1 {
  font-size: var(--fs-1);
  line-height: 1;
}

.h2 {
  font-size: var(--fs-2);
}

.h3 {
  font-size: var(--fs-6);
}

.h4 {
  font-size: var(--fs-7);
  font-weight: var(--fw-500);
}

.btn {
  background: var(--background, var(--eerie-black));
  color: var(--color, var(--white));
  font-weight: var(--fw-600);
  height: var(--height, 60px);
  padding-inline: 50px;
  border: 1px solid var(--border-color, var(--eerie-black));
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-primary:is(:hover, :focus) {
  --background: var(--white);
  --color: var(--eerie-black);
  --border-color: var(--eerie-black);
}

.btn-secondary {
  --height: 50px;
  --background: var(--white);
  --color: var(--eerie-black);
  --border-color: var(--white);
}

.btn-secondary:is(:hover, :focus) {
  --background: var(--eerie-black);
  --color: var(--white);
  --border-color: var(--eerie-black);
}

.btn-outline {
  --height: 50px;
  --background: var(--white);
  --color: var(--eerie-black);
  --border-color: var(--eerie-black);
  padding-inline: 40px;
}

.btn-outline:is(:hover, :focus) {
  --background: var(--eerie-black);
  --color: var(--white);
  --border-color: var(--eerie-black);
}

.section {
  padding-block: var(--section-padding);
}

.w-100 {
  width: 100%;
}

.section-title {
  text-align: center;
  margin-bottom: 30px;
}





/*-----------------------------------*\
 * #HEADER
\*-----------------------------------*/

.header-search {
  display: none;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--white);
  width: 100%;
  padding-block: 20px;
  z-index: 4;
  transition: var(--transition-1);
}

.header.active {
  position: fixed;
  box-shadow: 0 2px 20px hsla(0, 0%, 0%, 0.1);
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-open-btn {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
}

.nav-open-btn span {
  width: 20px;
  height: 2px;
  background: var(--black);
  transition: var(--transition-1);
}

.nav-open-btn span:nth-child(2) {
  transform: scaleX(0.5);
  transform-origin: right;
}

.nav-open-btn:is(:hover, :focus) span:nth-child(2) {
  transform: scaleX(1);
}

.header-actions {
  background: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  padding-block: 16px 10px;
  box-shadow: 0 -2px 20px hsla(0, 0%, 0%, 0.1);
  z-index: 1;
}

.header-action-btn {
  position: relative;
  /* width: ; */
  text-align: center;
}

.header-action-btn ion-icon {
  margin-inline: auto;
  font-size: 22px;
  margin-bottom: 3px;
}

.header-action-label {
  color: var(--sonic-silver);
  font-size: var(--fs-10);
  transition: var(--transition-1);
}

.header-action-btn:is(:hover, :focus) .header-action-label {
  color: var(--eerie-black);
}

.header-action-btn .btn-badge {
  position: absolute;
  top: -12px;
  right: -6px;
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  line-height: 0;
  font-size: 13px;
  color: var(--white);
  border-radius: 50%;
}

.header-action-btn .btn-badge.green {
  --background: var(--middle-blue-green);
}

.navbar {
  background: var(--white);
  position: fixed;
  top: 0;
  right: -300px;
  max-width: 300px;
  width: 100%;
  height: 100%;
  padding: 30px 25px;
  z-index: 3;
  visibility: hidden;
  transition: 0.25s var(--cubic-out);
}

.navbar.active {
  visibility: visible;
  transform: translateX(-300px);
  transition: 0.5s var(--cubic-in);
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

.nav-close-btn ion-icon {
  font-size: 23px;
}

.navbar-link {
  padding-block: 10px;
  color: var(--eerie-black);
}

.navbar-list>li:not(:last-child) {
  border-bottom: 1px solid var(--cultured);
}

.overlay {
  position: fixed;
  inset: 0;
  background: var(--black);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition-1);
  z-index: 2;
}

.overlay.active {
  opacity: 0.7;
  pointer-events: all;
}


/*-----------------------------------*\
 * #FOOTER
\*-----------------------------------*/

.footer-top {
  padding-block: var(--section-padding);
}

.footer-top .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

.footer .logo {
  margin-bottom: 20px;
}

.footer-text {
  line-height: 1.6;
  margin-bottom: 20px;
}

.social-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.social-link {
  font-size: 18px;
  width: 35px;
  height: 35px;
  border: 1px solid var(--sonic-silver);
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.footer-list {
  width: 100%;
}

.footer-list-title {
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  margin-bottom: 15px;
}

.footer-link {
  position: relative;
  color: inherit;
  padding-block: 5px;
}

.footer-link:is(:hover, :focus) {
  transform: translateX(15px);
}

.footer-link::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%) scale(var(--scale, 0));
  transform-origin: right;
  background: var(--sonic-silver);
  width: 10px;
  height: 2px;
  transition: var(--transition-1);
}

.footer-link:is(:hover, :focus)::before {
  --scale: 1;
}

.footer-bottom {
  padding-block: 16px;
}

.footer-bottom a {
  color: inherit;
}

.copyright {
  margin-bottom: 15px;
  text-align: center;
}

.copyright a {
  display: inline-block;
  font-weight: var(--fw-600);
}

.footer-bottom-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px 15px;
  margin-bottom: 15px;
}


.payment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}



/*-----------------------------------*\
 * #MEDIA QUARIES
\*-----------------------------------*/

/**
 * responsive for larger than 480px screen
 */

@media (min-width: 480px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 3rem;
    --fs-2: 2rem;

  }



  /**
   * CATEGORY
   */

  .category-list>li {
    width: calc(50% - 15px);
  }



  /**
   * PRODUCT
   */

  .product-list>li {
    width: calc(50% - 12.5px);
  }



  /**
   * FOOTER
   */

  .footer-brand {
    width: 100%;
  }

  .footer-text {
    max-width: 470px;
  }

  .footer-list {
    width: calc(50% - 20px);
  }

}





/**
 * responsive for larger than 580px screen
 */

@media (min-width: 580px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 3.75rem;
    --fs-2: 2.375rem;
    --fs-3: 2.25rem;

  }



  /**
   * REUSED STYLE
   */

  .container {
    max-width: 550px;
    margin-inline: auto;
  }

}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 700px;
  }



  /**
   * HERO
   */

  .hero {
    background-position: center;
    min-height: 800px;
  }



  /**
   * BLOG
   */

  .blog-list>li {
    width: calc(50% - 15px);
  }


  /**
   * FOOTER
   */

  .footer-list {
    width: calc(33.33% - 26.66px);
  }

  .copyright,
  .footer-bottom-list {
    margin-bottom: 0;
  }

  .footer-bottom .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px 50px;
  }

}





/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 5rem;

  }



  /**
   * RESET
   */

  body {
    padding-block: 0;
  }



  /**
   * REUSED STYLE
   */

  .container {
    max-width: 950px;
  }



  /**
   * HEADER
   */

  .header {
    position: relative;
    padding-block: 15px;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
    margin-bottom: 67px;
  }

  .header.active {
    position: relative;
    box-shadow: none;
  }

  .header .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }

  .header-search {
    display: block;
    position: relative;
    width: max-content;
  }

  .header-search .input-field {
    border: 1px solid hsla(0, 0%, 0%, 0.1);
    padding: 12px 30px;
    border-radius: 6px;
    transition: var(--transition-1);
  }

  .header-search .input-field:focus {
    outline: 1px solid var(--eerie-black);
  }

  .header-search .search-btn {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 20px;
    color: var(--eerie-black);
  }

  .header-search .search-btn ion-icon {
    --ionicon-stroke-width: 30px;
  }

  .header .logo {
    margin-inline: auto;
  }

  .nav-open-btn,
  .navbar-top,
  .overlay {
    display: none;
  }

  .header-actions {
    all: unset;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }

  .navbar {
    all: unset;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    background: var(--white);
  }

  .header.active .navbar {
    position: fixed;
    top: 0;
    box-shadow: 0 2px 20px hsla(0, 0%, 0%, 0.1);
  }

  .navbar-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .navbar-list>li:not(:last-child) {
    border-bottom: none;
  }

  .navbar-link {
    font-size: var(--fs-7);
    font-weight: var(--fw-500);
    padding-block: 20px;
  }

  .navbar-link:is(:hover, :focus) {
    color: #111827;
  }
}

/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 6.25rem;

  }



  /**
   * REUSED STYLE
   */

  .container {
    max-width: 1150px;
  }

  /**
   * FOOTER
   */

  .footer-brand {
    width: calc(40% - 30px);
  }

  .footer-text {
    max-width: 340px;
  }

  .footer-list {
    width: calc(20% - 30px);
  }

  .footer-bottom .container {
    justify-content: space-between;
  }

}

.borderBar {
  border-top: 5px solid #1A56DB;
  color: #1A56DB;
  font-weight: 700;
}

.borderBarWhite {
  border-top: 5px solid #F3F4F6;
  font-weight: 700;
}

.myborder {
  border: 1px solid #D1D5DB;
}

#react-select-2-input:focus {
  border: 0;
}

.PhoneInputInput:focus {
  box-shadow: none;
}

.error-border {
  border: 1px solid #C81E1E;
}

/* Remove arrows from number input (Webkit browsers like Chrome, Safari, Edge) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows from number input (Firefox) */
input[type="number"] {
  -moz-appearance: textfield;
}

.outlined-coupon {
  font-size: 5rem;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 2px teal;
  /* Outline color */
}